import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import CustomModal from "../Modals";
import { useUser } from "../../UserContext";
import Wallet from "../Shop/Wallet";

interface WalletProps {
  profilePic?: string;
  username?: string;
  wallet: {
    coins: string;
    rubies: string;
  };
  showWallet: boolean;
  goBack?: () => void;
}

const WalletDisplay = ({
  profilePic,
  username,
  wallet,
  showWallet,
  goBack,
}: WalletProps) => {
  const [isAnimatingCoins, setIsAnimatingCoins] = useState<boolean>(false);
  const [isAnimatingRubies, setIsAnimatingRubies] = useState<boolean>(false);
  const [isUpdatedCoins, setIsUpdatedCoins] = useState<boolean>(false);
  const [isUpdatedRubies, setIsUpdatedRubies] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  function toggleOpen() {
    setOpen((open) => !open);
  }

  const [coins, setCoins] = useState<number>(0);
  const [rubies, setRubies] = useState<number>(0);

  async function getWallet() {
    const session_token = Cookies.get("c8_session_token");
    if (!session_token) return;

    try {
      const response = await fetch(
        `${process.env.API_URL}/account/${session_token}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.API_KEY as string,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setCoins(data.data?.wallet?.coins || 0);
        setRubies(data.data?.wallet?.rubies || 0);
      }
    } catch (error) {
      console.error("Error fetching wallet:", error);
    }
  }

  useEffect(() => {
    getWallet().catch(console.error);
  }, []);

  async function UpdateUserAmount(offerId: string, offerType: string) {
    const session_token = Cookies.get("c8_session_token");
    if (!session_token) return;

    try {
      const res = await fetch(`${process.env.API_URL}/buy`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process.env.API_KEY as string,
        },
        body: JSON.stringify({
          user_session: session_token,
          offer_id: offerId,
        }),
      });

      const response = await res.json();
      const { data } = response;

      if (data?.coins !== undefined && offerType === "soft") {
        await updateCoins(data.coins);
        setRubies(data.rubies);
      }

      if (data?.rubies !== undefined && offerType === "hard") {
        await updateRubies(data.rubies);
      }
    } catch (error) {
      console.error("Error updating user amount:", error);
    }
  }

  async function updateCoins(coins: number) {
    setCoins(coins);
    setIsAnimatingCoins(true);
    setIsUpdatedCoins(true);
    const timeout = setTimeout(() => {
      setIsAnimatingCoins(false);
      setIsUpdatedCoins(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }

  async function updateRubies(rubies: number) {
    setRubies(rubies);
    setIsAnimatingRubies(true);
    setIsUpdatedRubies(true);
    const timeout = setTimeout(() => {
      setIsAnimatingRubies(false);
      setIsUpdatedRubies(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }

  const { user } = useUser();

  return (
    <>
      {showWallet ? (
        <div className="w-full h-[56px]">
          <div className="px-2 lg:px-14 flex flex-row justify-between items-center h-full">
            {goBack && (
              <div className="flex flex-row gap-0.5">
                <div
                  onClick={goBack}
                  className="w-[25px] h-[25px] flex flex-row items-center justify-center font-anton text-[#1b062f] text-[10px] bg-[#d2ff00] cursor-pointer gap-1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14px"
                    height="14px"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="m14 18l-6-6l6-6l1.4 1.4l-4.6 4.6l4.6 4.6z"
                    />
                  </svg>
                </div>
                <div
                  className="flex justify-center items-center gap-1 w-[64px] h-[25px] text-[10px] bg-[#d2ff00] cursor-pointer text-main"
                  onClick={goBack}
                >
                  <span>All Games</span>
                </div>
              </div>
            )}
            {user && (
              <div className="w-full flex flex-row justify-end">
                <div className="font-anton text-white text-[18px] px-[20px] h-[40px] bg-subhead flex justify-center items-center gap-1 mr-[3px]">
                  <img
                    alt="Diamond"
                    src={`${process.env.CDN_ENDPOINT}/assets/single_diamond.png`}
                    className="h-[30px] mt-[5px]"
                  />
                  <div>{wallet.rubies}</div>
                </div>
                <div className="font-anton text-white text-[18px] px-[20px] h-[40px] bg-subhead flex justify-center items-center gap-1">
                  <img
                    alt="Coins"
                    src={`${process.env.CDN_ENDPOINT}/assets/single_coin.png`}
                    className="h-[30px]"
                  />
                  <div>{wallet.coins}</div>
                </div>
                <div
                  onClick={() => toggleOpen()}
                  className="bg-lime cursor-pointer h-[40px] w-[60px] font-anton text-main text-center text-[14px] font-normal flex justify-center items-center"
                >
                  SHOP
                </div>
                <>
                  <CustomModal
                    isOpen={open}
                    onRequestClose={toggleOpen}
                    contentLabel="BUY"
                  >
                    <>
                      <Wallet />
                    </>
                  </CustomModal>
                </>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default WalletDisplay;
