import { useEffect, useState } from "react";
import WalletDisplay from "./wallet-display";
import Cookies from "js-cookie";
import React from "react";
import { useUser } from "../../UserContext";

const Squares = () => {
  const session_token = Cookies.get("c8_session_token");
  const { user } = useUser();

  const [iframeSrc, setIframeSrc] = useState("");
  const [showWallet, setShowWallet] = useState(false);
  const [userAccount, setUserAccount] = useState({
    id: "",
    username: "",
    profile_pic: "",
    wallet: {
      coins: "",
      rubies: "",
    },
  });
  useEffect(() => {
    const fetchAccount = async () => {
      const response = await fetch(
        `${process.env.API_URL}/account/${session_token}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.API_KEY as string,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setUserAccount({
          username: data.data.username,
          profile_pic: data.data.profile_pic,
          id: data.data.wallet.account_id,
          wallet: {
            coins: data.data.wallet.coins,
            rubies: data.data.wallet.rubies,
          },
        });
        setShowWallet(true);
      }
    };
    fetchAccount();

    if (!user) {
      setShowWallet(true);
    }
  }, []);

  useEffect(() => {
    setIframeSrc(
      `${process.env.GAMES_URL}?userId=${userAccount.id}&userName=${
        userAccount.username
      }&profilePic=${encodeURIComponent(
        userAccount.profile_pic
      )}&sessionToken=${session_token}`
    );
  }, [userAccount]);

  function goBack() {
    location.href = "/games";
  }

  return (
    <div id="content" className="min-h-[75vh] mt-[0px] flex flex-col">
      <WalletDisplay
        wallet={userAccount.wallet}
        showWallet={showWallet}
        profilePic={undefined}
        username={undefined}
        goBack={goBack}
      />
      <div className="flex flex-grow w-full h-full min-h-full">
        <iframe
          title="squaresIframe"
          src={iframeSrc}
          frameBorder="0"
          style={{ display: "flex", flexGrow: 1 }}
          className="border-0"
        ></iframe>
      </div>
    </div>
  );
};

export default Squares;
